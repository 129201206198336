import React from "react";
import {Switch, Redirect} from "react-router-dom";
import {
	RouteWithLayout
} from "../components";
import {
	Minimal as MinimalLayout
} from "../layouts";
import {
	Authorization as AuthorizationPage
} from "../pages";

const pages = [
	{
		path: '/',
		component: AuthorizationPage,
		layout: MinimalLayout,
		rules: [],
		exact: true
	},
];

const MainRoutes = () => {
	return (
		<Switch>
			{
				pages.map((page, idx) => (
					<RouteWithLayout
						key={'page-' + idx}
						{...page}
					/>
				))
			}

			<Redirect to="/"/>
		</Switch>
	);
};

export default MainRoutes;
